import './Reviews.scss';

import { EffectCards, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import KmongAvatar from 'assets/kmong-avatar.png';

const reviews = [
  {
    author: 'swe*****',
    date: '23.06.23 11:42',
    content:
      '친절하게 상담해 주시고 피드백이 빨라서 소통이 잘 되었습니다.\n결과물도 처음 기획 의도대로 잘 구현되었고\n생각지 못했던 필요한 부분은 알아서 개발해 주셔서 감사했습니다,\n\n다음 번에 또 의뢰 드리고 싶습니다!',
    duration: 30,
  },
  {
    author: '너*****',
    date: '22.07.07 15:55',
    content:
      '첫 작업 의뢰였는데 너무 센스있게 잘 해주셔서 10000%만족했습니다.\n다음에도 같이 작업 하면 너무 좋을 것 같아요.\n감사합니다. ^^!',
    duration: 9,
  },
  {
    author: '푸*****',
    date: '22.05.11 18:01',
    content: `정말 최고이십니다!\n친절하시고 열심히 작업해주셨습니다!\n제가 원했던 결과물의 만족도 그이상을 해주셨습니다!\n인터렉티브디벨로퍼님과 작업을 고민하시는분들은 고민하시지마시고 지금바로 진행하세요!`,
    duration: 15,
  },
  {
    author: 'KJ',
    date: '22.06.08 13:50',
    content:
      '수정 요청사항이 많았음에도 불구하고\n모든 사항을 잘 반영해주셨고\n기간 내 결과물을 완벽하게 구현해주셨습니다.\n\n특히 정말 원활한 소통을 할 수 있어서 대단히 만족스럽습니다.\n감사합니다 :)',
    duration: 29,
  },
  {
    author: '대*****',
    date: '24.02.13 09:54',
    content:
      '일정, 속도, 퀄리티 모두 만족합니다. 예상했던 것보다 빠른 일정으로 마무리해주셨고, 작업 퀄리티, 대응 속도 역시 모두 만족했습니다.\n\n기획안에서 부족할 수 있었던 부분도 센스있게 잘 대응해주셔서 대 만족하며 프로토타이핑을 마무리했습니다.',
    duration: 43,
  },
  {
    author: '말*****',
    date: '22.07.18 09:24',
    content:
      '거래가 처음인데 거래 과정 내내 친절하시고 상세하게 알려주셔서 너무 좋았어요 ㅎㅎ 다음에 기회가 된다면 또 이용하고 싶네요!',
    duration: 1,
  },
];

const Reviews = () => {
  return (
    <section className="section reviews-sec">
      <div className="page-padding">
        <div className="container">
          <div className="section-header">
            <h2 className="section-heading">
              나의 고객들이
              <br />
              전하는 리뷰
            </h2>
          </div>
          <div>
            <Swiper
              effect="cards"
              modules={[EffectCards, Pagination]}
              cardsEffect={{
                slideShadows: false,
                perSlideRotate: 3,
              }}
              pagination={{
                el: '.reviews-pagination',
                clickable: true,
              }}
            >
              {reviews.map((r) => (
                <SwiperSlide key={r.date}>
                  <ReviewItem {...r} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="reviews-pagination" />
          </div>
        </div>
      </div>
    </section>
  );
};

const ReviewItem = ({
  author,
  date,
  content,
  duration,
}: {
  author: string;
  date: string;
  content: string;
  duration: number;
}) => (
  <>
    <div className="review-heading">
      <img className="avatar" src={KmongAvatar} alt="avatar" loading="lazy" />
      <div className="author-info">
        <span className="author-name">{author}</span>
        <div className="review-heading-bottom">
          <div className="rating-wrap">
            <div className="stars">
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </div>
            <span className="rating">5.0</span>
          </div>
          {/* <span className="divider">|</span> */}
          {/* <span className="review-date">{date}</span> */}
        </div>
      </div>
    </div>
    <p className="review-content">{content}</p>
    <span className="project-duration">작업일: {duration}일</span>
  </>
);

export default Reviews;
